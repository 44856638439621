import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: 'vooBank'
    },
    component: Home,
  },
  {
    path: "/Products",
    name: "products",
    meta: {
      title: 'products-vooBank'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "products" */ "@/views/products.vue"),
  },
  {
    path: "/News",
    name: "news",
    meta: {
      title: 'news-vooBank'
    },
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/news.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: 'contact-vooBank'
    },
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/contact.vue"),
  },
  {
    path: "/support",
    name: "support",
    meta: {
      title: 'support-vooBank'
    },
    component: () =>
      import(/* webpackChunkName: "support" */ "@/views/support.vue"),
  },
  {
    path: "/legal",
    name: "legal",
    meta: {
      title: 'legal-vooBank'
    },
    component: () =>
      import(/* webpackChunkName: "legal" */ "@/views/legal.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
