import { createI18n } from "vue-i18n"

import zh from "./zh"   // 中文
import en from "./en"   // 英文
import jap from "./jap"   // 日文
import kor from "./kor"   // 韩文
import ger from "./ger"   // 德文
import vie from "./vie"   // 越南文
// 准备翻译的语言环境信息
const messages = {
    'en': {
        ...en
    },
    'zh': {
        ...zh
    },
    'jap': {
        ...jap
    },
    'kor': {
        ...kor
    },
    'ger': {
        ...ger
    },
    'vie': {
        ...vie
    }
}
// 默认语言 - 
const default_lang = "en"

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem("currentLanage") || default_lang,
    globalInjection: true,  // 全局模式，可以直接使用 $t
    messages: messages,
    silentTranslationWarn: true

})

export default i18n //对外暴露 i18n, 在 main.js 中挂载