<template>
	<div class="footer">
		<div class="left">
			<img src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVtpb5uowKNwe8ym7z82czbsSZrrTQLsoeBub5b2THJJ8" alt="" class="logo" />
			<div class="icon_box">
				<a href="https://x.com/M3DAO_global" target="_blank">
					<img src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTbbt3Ci8VQibp4aFqpZQ4j1mqXheuiA7rHJHAWSrbVye/icon1.png" alt=""
				/></a>
				<a href="https://t.me/M3DAO" target="_blank">
					<img src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTbbt3Ci8VQibp4aFqpZQ4j1mqXheuiA7rHJHAWSrbVye/icon2.png" alt=""
				/></a>
				<a href="https://discord.gg/marsversem3" target="_blank">
					<img src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTbbt3Ci8VQibp4aFqpZQ4j1mqXheuiA7rHJHAWSrbVye/icon3.png" alt=""
				/></a>
				<a href="https://www.youtube.com/@MarsVerseM3" target="_blank">
					<img src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTbbt3Ci8VQibp4aFqpZQ4j1mqXheuiA7rHJHAWSrbVye/icon4.png" alt=""
				/></a>
				<a href="https://medium.com/m3-dao" target="_blank">
					<img src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTbbt3Ci8VQibp4aFqpZQ4j1mqXheuiA7rHJHAWSrbVye/icon5.png" alt=""
				/></a>
				<!-- <a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target="_blank">
					<img src="@/assets/footer/icon6.png" alt=""
				/></a> -->
			</div>
			<!-- <div class="email">
				<a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target="_blank">
					Please contact [contact@m3dao.io] for more information.</a
				>
			</div> -->
		</div>
		<div class="right">
			<div class="one">
				<div @click="go(1, 'https://www.mars-verse.io/')" class="title">
					{{ $t('footer.one.title') }}
				</div>
				<div class="item">{{ $t('footer.one.item1') }}</div>
				<div class="item">{{ $t('footer.one.item2') }}</div>
				<div class="item">{{ $t('footer.one.item3') }}</div>
				<div class="item">{{ $t('footer.one.item4') }}</div>
				<div class="item">{{ $t('footer.one.item5') }}</div>
				<div class="item">{{ $t('footer.one.item6') }}</div>
			</div>
			<div class="two">
				<div class="title">{{ $t('footer.two.title') }}</div>
				<div @click="go(1, 'https://marsverses-organization.gitbook.io/marsverse/')" class="item">
					{{ $t('footer.two.item1') }}
				</div>
				<div @click="go(2, '/media')" class="item">{{ $t('footer.two.item2') }}</div>
				<div @click="go(2, '/contact')" class="item">
					{{ $t('footer.two.item3') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
// import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const router = useRouter();
const go = (type, url) => {
	if (type == 1) {
		window.open(url, '_blank');
	} else {
		router.push(url);
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
	width: 100%;
	height: 400px;
	color: #ffffff;
	background: rgba(13, 13, 13, 1);
	padding: 61px 364px 171px;
	box-sizing: border-box;
	display: flex;
}

/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.left {
		width: 100%;
		padding-top: 8px;
		.logo {
			width: 304px;
			margin-bottom: 50px;
		}
		.icon_box {
			display: flex;
			a {
				img {
					width: 40px;
					height: 40px;
					margin-right: 15px;
				}
			}

			a:nth-child(5) {
				img {
					margin-right: 0;
				}
			}
		}
		.email {
			margin-top: 22px;
			font-weight: 400;
			font-size: 16px;
		}
	}
	.right {
		display: flex;
		.one {
			margin-right: 220px;
			.title {
				font-size: 20px;
				font-weight: 900;
				margin-bottom: 21px;
				cursor: pointer;
			}
			.item {
				margin-bottom: 12px;
				font-size: 16px;
				// width: 154px;
				word-break: keep-all;
				white-space: nowrap;
				cursor: pointer;
			}
		}
		.two {
			.title {
				font-size: 20px;
				font-weight: 900;
				margin-bottom: 21px;
				cursor: pointer;
			}
			.item {
				margin-bottom: 12px;
				font-size: 16px;
				// width: 14px;
				word-break: keep-all;
				white-space: nowrap;
				cursor: pointer;
			}
		}
	}
}
/* max-width 最大宽度  当前屏幕小于992px会应用当前媒体查询里面的样式 */
@media screen and (max-width: 768px) {
	.footer {
		flex-direction: column;
		height: 721px;
		padding: 157px 35px 82px;
		.left {
			display: flex;
			flex-direction: column;
			align-items: center;
			.logo {
				width: 150px;
				height: 70px;
			}
			.icon_box {
				display: flex;
				margin: 35px 0 26px;
				a {
					img {
						width: 40px;
						height: 40px;
						margin-right: 15px;
					}
				}
				a:nth-child(5) {
					img {
						margin-right: 0;
					}
				}
			}
			.email {
				width: 250px;
				text-align: center;
				font-weight: 400;
				font-size: 14px;
			}
		}
	.right {
		display: flex;
		margin-top: 62px;
		.one {
			margin-right: 95px;
			.title {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 20px;
				cursor: pointer;
			}
			.item {
				font-size: 14px;
				margin-bottom: 12px;
				word-break: keep-all;
				white-space: nowrap;
				cursor: pointer;
			}
		}
		.two {
			.title {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 20px;
				cursor: pointer;
			}
			.item {
				font-size: 14px;
				margin-bottom: 12px;
				word-break: keep-all;
				white-space: nowrap;
				cursor: pointer;
			}
		}
	}
	}
}
</style>
