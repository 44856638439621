<template>
	<swiper
		:space-between="10"
		:slides-per-view="2"
		navigation
		@swiper="onSwiper"
		@slideChange="onSlideChange"
		:grabCursor="true"
	>
		<swiper-slide
			v-for="(item, index) in state.iconList"
			:key="index"
			class="swiper_item"
			@mouseover="eoverLeftArrow(index + 1)"
			@mouseleave="eleaveLeftArrow(index + 1)"
		>
			<img :src="`${state.http}/${item.src}.png`" alt="" />
			<p>Worry-free</p>
			<p>Global Payments</p>
			<span
				>Supports Visa and MasterCard, with payments and withdrawals in over 150 countries.</span
			>
		</swiper-slide>
	</swiper>
</template>
<script setup>
import { reactive } from 'vue';
// import Swiper core and required components
import SwiperCore, { Navigation, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
require('swiper/swiper.scss');
require('swiper/components/navigation/navigation.scss');

// install Swiper components
SwiperCore.use([Navigation, A11y]);

const state = reactive({
	http: 'https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmPJ3BEKAhXB4tafQwHVBVjHDBfygFzny8MEGDcH8w8sWT',
	iconList: [
		{
			href: '',
			src: '1',
		},
		{
			href: '',
			src: '2',
		},
		{
			href: '',
			src: '3',
		},
		{
			href: '',
			src: '4',
		},
	],
});
const eoverLeftArrow = (item) => {
	state.iconList.map((items) => {
		if (items.src == item) {
			items.src = item + '_hover';
			console.log(items.src, 'in');
		}
	});
};
const eleaveLeftArrow = (item) => {
	state.iconList.map((items) => {
		if (items.src == item + '_hover') {
			items.src = item;
			console.log(items.src, 'out');
		}
	});
};
const onSwiper = (swiper) => {
	// console.log(swiper)
};
const onSlideChange = () => {
	// console.log('slide change')
};
</script>
<style lang="scss">
.swiper-container {
	overflow: visible;
}
.swiper-button-prev,
.swiper-button-next {
	top: -100px;
}
.swiper-wrapper {
	transition: all 1s !important;
}
.swiper-button-prev {
	left: auto !important;
	right: 440px !important;
}
.swiper-button-next {
	left: auto !important;
	right: 360px !important;
}
.swiper-button-prev::after {
	content: 'prev';
}
.swiper-button-next::after {
	content: 'next';
}
.swiper-button-prev,
.swiper-button-next {
	top: -100px !important;
	color: #ffffff !important;
	border: 1px solid #ffffff !important;
	border-radius: 50% !important;
	width: 48px !important;
	height: 48px !important;
	&::after {
		font-size: 12px !important;
		color: white !important;
	}
}
.swiper_item {
	width: 507px !important;
	height: 434px;
	border: 1px solid #986226;
	border-radius: 10px;
	margin-right: 27px !important;
	padding: 82px 0 52px 18px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	img {
		width: 91px;
		height: 91px;
		margin-bottom: 27px;
	}
	p {
		font-size: 49px;
		font-weight: 300;
		line-height: 61.25px;
	}
	span {
		margin-top: 10px;
		font-size: 20px;
		font-weight: 400;
		line-height: 25px;
		color: #cccccc;
	}
}
.swiper_item:hover {
	background: #ca8232;
}
</style>
