module.exports = {
    tabList: {
        list1: 'Home',
        list2: 'Products and Services',
        list3: 'News and Announcements',
        list4: 'Contact Us',
        list5: 'Support Center',
        list6: 'Legal and Privacy',
        // list7: 'Grant',
        // list8: 'Blog',
    },
    home: {
        list0: "Voobank Digital Asset Card Functions and Advantages",
        list1: {
            title: "Diverse Asset Integration",
            font: "Voobank supports a wide range of cryptocurrencies and integrates seamlessly with traditional financial systems, enabling users to easily convert and manage various assets for daily spending or long-term investment.",
        },
        list2: {
            title: "Flexible Card Options",
            font: "Voobank offers both virtual and physical card options, catering to different user preferences. The virtual card is perfect for immediate use, while the physical card allows cash withdrawals at ATMs globally.",
        },
        list3: {
            title: "Secure Transaction Experience",
            font: "Utilizing advanced security technology, Voobank ensures comprehensive protection for every transaction, safeguarding user assets and preventing fraud, thus providing unparalleled trust and security.",
        },
        list4: {
            title: "User-Friendly Interface",
            font: "The Voobank interface features an intuitive dashboard that allows users to easily view their digital assets, transaction history, and account balances. With one-click options for withdrawals and deposits, managing funds is efficient, and the instant recharge feature lets users quickly add cryptocurrencies in just a few clicks. This design simplifies navigation and enhances the overall user experience.",
        },
        list5: {
            title: "Real-Time Currency Exchange",
            font: "With a key highlight on real-time currency exchange, users can instantly convert cryptocurrencies to fiat currencies during transactions, benefiting from the best market rates within seconds.",
        },
        case_title: "Use Case",
        case1: "Crypto Spending at Merchants",
        case2: "Online Purchase with Crypto",
        case3: "Cross-Border Payment with Crypto",
        case4: "Cash Withdrawal from ATM",
        fees_title: "Card Fees",
        
        slogan1: "REDEFINIING GLOBAL",
        slogan2: "PAYMENT AND",
        slogan3: "BANKING EXPERIENCE",
        font: "Enjoy the security and convenience of global finance through Voobank's Digital Asset Card and digital banking services.",
        about: "About Us",
        CTA: "Sign Up & CTA",
        Backing: "Partners & Backing",
        Core: "Core Features Overview",

        
    },
    contact: {
        title: "Contact Us",
        font1: "24/7 CS Support via Multiple Channels",
        input1: 'Name:',
        input2: 'Email:',
        input3: 'Phone:',
        input4: 'Your Inquiry:',
        button2: 'Return',
        button1: 'CONFIRM',
        dialog: "Submitted successfully",
        icon_title: "Follow Our Socials"
    },
    news: {
        title: "News & Announcements",
        News: "News",
        Announcements: "Announcements"
    },
    support: {
        title: "Support Center",
        font1: "Your Card Journey, Our Commitment!",
        font2: "At MarsVerse, we prioritize our customers' needs and strive to provide exceptional support at every step of their journey. Our dedicated Support Center is available 24/7, ensuring that assistance is just a click away whenever you need it. ",
        font3: "Whether you have questions about your account, need help with transactions, or require guidance on using our crypto credit card features, our knowledgeable team is ready to assist you with personalized solutions. We also prepare detailed tutorials and a comprehensive FAQ section to help you understand how to use your Voobank Digital Asset Card effectively. ",
        icon_title: "FAQ"
    },

    Product: {
        mv1: "Avatar",
        mv2: "Interstellar",
        mv3: "Colonization",
        mv4: "RWA Platform",
        mc1: "Layer2",
        mc2: "DID",
        mc3: "Infrastructure",
        mc4: "Supporting Ecosystem",
        mp1: "MarsLab",
        mp2: "Web3 Investment and Incubation",
        mp3: "Digital Payment Solutions",
        mp4: "DAO Foundation",
    },
    footer: {
        one: {
            title: "Ecosystem",
            item1: "MarsVerse",
            item2: "MarsChain",
            item3: "MarsProtocol",
            item4: "Avatar",
            item5: "MarsLab",
            item6: "Blockchain Explorer",

        },
        two: {
            title: "Support",
            item1: "About",
            item2: "Media Kit",
            item3: "Contact Us",

        }
    }
}